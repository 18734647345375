<template>
  <p v-html="title"></p>
  <!--  <p v-html="sldHorLine"></p>-->
  <el-form :model="settingForm" label-width="80px" v-if="settingFlag">
    <el-table :show-header="false" :data="tableData" border style="width: 100%">
      <el-table-column
        prop="date"
        min-width="200"
        align="right"
        :label="L['新消息声音提醒']"
      >
      </el-table-column>
      <el-table-column align="left" min-width="700">
        <template #default="scope">
          <template v-if="scope.row.type == 'text'">
            <el-form-item label-width="0">
              <el-switch
                v-model="settingForm.voice"
                @change="switchVoice"
              ></el-switch>
            </el-form-item>
          </template>
          <template v-if="scope.row.type == 'btn'">
            <el-button type="primary" size="small" @click="onSubmit">{{
              L['保存']
            }}</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>
<script>
import { getCurrentInstance, reactive, onMounted,ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from "vuex";

export default {
  name: "Setting",
  components: {},
  setup () {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const title = proxy.$sldLlineRtextAddGoodsAddMargin('#0563FF', '聊天设置', 0, 0, 15);
    const sldHorLine = proxy.$getSldHorLine(1);
    const settingForm = reactive({ voice: false });//表单数据
    const settingFlag= ref(false);//
    const identity = localStorage.identity
    const L = proxy.$getCurLanguage()

    //获取消息提示设置，在用户登录页面写完时将此方法移至登录页面
    const getSetting = () => {
      proxy.$post(`v3/helpdesk/${identity}/sound/setting/isOpen`).then(res => {
        settingForm.voice = res.data.isOpen ? true : false
        // settingFlag.value = true
      }).finally(() => {
          settingFlag.value = true
        })
    }

    //开关方法
    const switchVoice = (e) => {
      settingForm.voice = e;
    }

    //保存数据
    const onSubmit = () => {
      let params = {
        isOpen: settingForm.voice ? 1 : 0
      }
      proxy.$post(`v3/helpdesk/${identity}/sound/setting/update`, params).then(res => {
        ElMessage.success(res.msg)
        store.commit("updateMsgVoiceSetting", settingForm.voice);
      })
    }



    onMounted(() => {

      getSetting()
    })



    return {
      L,
      title,
      sldHorLine,
      settingForm,
      tableData: [{
        date: '新消息声音提醒',
        name: 222,
        type: 'text'
      }, {
        date: '',
        name: 222,
        type: 'btn'
      },],
      onSubmit,
      switchVoice,
      getSetting,
      settingFlag
    };
  }
};
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>